<script>
export default {
  name: "erjiyangpin",
  data() {
    return {
      regname :regname,
      type: "2",
      number: '',
      task_info: false,
      task_content: [],
      item_list: [],
      info_show: false,

      shenhe_list: [],
      bianzhi_list: [],
      jiaoyanren_list: [],

      jianceren: [],
      jiaoyanren: [],
      shenheren: [],
      caijiren_list: [],

      fuzeren_list: [],
      fuzeren_list1: '',
    }
  },
  mounted() {
    this.getJiaoyanrenList()
    this.getbianzhiList()
    this.getShenheList()

    this.shenherenList()
    this.jiancerenList()
    this.jiaoyanrenList()
    this.getCaijiren()
  },
  methods: {

    getCaijiren() {
      this.$sa0.post({
        url: this.$api('样品管理：采集人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.caijiren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    jiancerenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：检测人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jianceren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    jiaoyanrenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：校验人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    shenherenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：审核人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenheren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    getJiaoyanrenList() {
      this.$sa0.post({
        url: this.$api('任务:校验人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getShenheList() {
      this.$sa0.post({
        url: this.$api('任务:审核人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenhe_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getfuzerenList() {
      this.$sa0.post({
        url: this.$api('委托单：负责人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.fuzeren_list = response.data.list;
            this.fuzeren_list1 = response.data;

            // if (Number(this.task_info.check_id) == 0) {
            //   this.task_info.check_id = response.data.admin_id
            // }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getbianzhiList() {
      this.$sa0.post({
        url: this.$api('任务:编制人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.bianzhi_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    imageUpload(e, key, k) {
      // 这个是 上传图片 的函数
      let param = new FormData()
      param.append('file', e.target.files[0])
      this.$sa0.file({
        url: this.$api('上传图片'),
        data: param
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          error: () => {
            layer.msg('上传失败')
          },
          then: (res) => {
            layer.msg('上传成功')
            this.$set(this.task_content[key].value, k, res.data.url)
          }
        })
      })
    },
    getClick() {
      this.$sa0.post({
        url: this.$api('二级：保存下级样品委托单'),
        data: {
          info: this.task_info,
          content: this.task_content,
          list: this.item_list,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.$router.push('/samplelist/samplilist/0?id=' + response.data.id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    searchClick() {
      this.$sa0.post({
        url: this.$api('二级：搜索下级样品委托单'),
        data: {
          type: this.type,
          number: this.number,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_info = response.data.info
            this.task_info.check_id = 0
            this.task_info.bianzhi_id = 0
            this.task_info.jiaoyan_id = 0
            this.task_info.shenhe_id = 0
            this.task_info.com_name = this.regname
            this.task_content = response.data.content.map((item) => {
              let con = item
              let content = con.table_content
              let value = ''
              // 1567 直等  234 解JSON
              switch (con.type) {
                case 0:
                case 1:
                case 5:
                case 6:
                case 7:
                  content = con.table_content;
                  break;
                case 2:
                case 3:
                case 4:
                  content = JSON.parse(con.table_content);
                  break;
              }
              // 12457 直等  36 解JSON
              switch (con.type) {
                case 0:
                case 1:
                case 2:
                case 4:
                case 5:
                case 7:
                  value = con.taskcontent
                  break;
                case 3:
                case 6:
                  value = JSON.parse(con.taskcontent)
                  break;
              }
              con.value = value;
              con.content = content;
              return con
            })

            this.item_list = response.data.list.map(item => {
              item.pick_per = 0
              item.other_pick_per = []
              return item
            })
            this.getfuzerenList()
            this.info_show = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  },
}
</script>
<template>
  <div>
    <div>
      <a-space>
        <a-radio-group v-model:value="type">
          <a-radio value="1">委托单</a-radio>
          <a-radio value="2">样品</a-radio>
        </a-radio-group>
        <a-input style="width: 300px;" v-model="number"></a-input>
        <a-button type="primary" @click="searchClick()">搜索</a-button>
        <a-button v-if="info_show" type="primary" @click="getClick()">接收</a-button>
      </a-space>
    </div>
    <div v-if="info_show">
      <div class="table_wrapper ml-5">
        <table class="layui-table">
          <colgroup>
            <col width="200">
            <col width="250">
            <col width="150">
            <col width="200">
            <col width="100">
            <col width="200">
          </colgroup>
          <tbody>
          <tr>
            <td class="table_title_bg">委托单名称</td>
            <td colspan="3" class="text-l">
              <a-input v-model="task_info.name" type="text"/>
            </td>

            <td class="table_title_bg">负责人</td>
            <td>
              <a-select v-model="task_info.check_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(bzi,bzk) in fuzeren_list" :value="bzi.id">{{ bzi.name }}
                </a-select-option>
              </a-select>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg"> 委托单编号</td>
            <td>
              <a-space style="position: relative">
                <a-input placeholder="委托单编号" style="width: 250px" v-model="task_info.task_num"></a-input>
              </a-space>

            </td>
            <td class="table_title_bg">委托单位名称</td>
            <td>
              <a-input v-model="task_info.com_name" style="width: 200px" placeholder="请输入委托单位名称"></a-input>
            </td>
            <td class="table_title_bg">委托类型</td>
            <td>
              <a-select v-model="task_info.task_type" class="w-full">
                <a-select-option :value="1">内部</a-select-option>
                <a-select-option :value="2">外部</a-select-option>
              </a-select>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">报告编制人</td>
            <td>
              <a-select v-model="task_info.bianzhi_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(bzi,bzk) in bianzhi_list" :value="bzi.id">{{ bzi.name }}
                </a-select-option>
              </a-select>
            </td>
            <td class="table_title_bg">报告校验人</td>
            <td>
              <a-select v-model="task_info.jiaoyan_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(jyi,jyk) in jiaoyanren_list" :value="jyi.id">{{ jyi.name }}
                </a-select-option>
              </a-select>
            </td>
            <td class="table_title_bg">报告审核人</td>
            <td>
              <a-select v-model="task_info.shenhe_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(shi,shk) in shenhe_list" :value="shi.id">{{ shi.name }}
                </a-select-option>
              </a-select>
            </td>

          </tr>
          <tr>
            <td class="table_title_bg">联系人</td>
            <td>
              <a-input v-model="task_info.com_per" type="text"/>
            </td>
            <td class="table_title_bg">电话</td>
            <td>
              <a-input v-model="task_info.com_phone" type="text"/>
            </td>
            <td class="table_title_bg">办理时间</td>
            <td>
              <a-date-picker v-model="task_info.input_date" class="w-full" show-time format="YYYY-MM-DD HH:mm:ss"
                             valueFormat="YYYY-MM-DD HH:mm:ss"/>
            </td>
          </tr>
          <tr v-for="(item,key) in task_content" :key="key">
            <td class="table_title_bg" :class="[item.not_null === 1?'text-red-400':'']">{{ item.title }}
            </td>
            <td colspan="5 " class="text-l">
              <div class="h-full" v-if="item.type === 1">
                <a-input class="w-full h-full input_wrapper" :placeholder="item.content" v-model="item.value"></a-input>
              </div>
              <div class="input_select_wrapper" v-if="item.type === 2">
                <a-select class="h-full w-full" :default-value="item.content[0]" v-model="item.value">
                  <a-select-option :value="i" v-for="(i,k) in item.content">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 3">
                <a-checkbox-group v-model="item.value">
                  <a-checkbox v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-checkbox>
                </a-checkbox-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 4">
                <a-radio-group v-model="item.value">
                  <a-radio v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 5">
                <a-textarea v-model="item.value" :placeholder="item.content"></a-textarea>
              </div>
              <div class="content_image_item_wrapper" v-if="item.type === 6">
                <div class="image_item_wrapper" v-for="(i,k) in Number(item.content)">
                  <input type="file" class="image_input_wrapper" @change="(e)=>{imageUpload(e,key,k)}">
                  <div class="image_image_wrapper" v-if="item.value[k] !== ''">
                    <img :src="item.value[k]"/>
                  </div>
                  <a-icon type="plus"/>
                </div>
              </div>
              <div class="content_image_item_wrapper input_line_wrapper" v-if="item.type === 7">
                <a-date-picker v-model="item.value" v-if="Number(item.content) === 0" format="YYYY-MM-DD"
                               valueFormat="YYYY-MM-DD" style="width: 400px"/>
                <a-time-picker v-model="item.value" v-if="Number(item.content) === 1" format="HH:mm:ss"
                               valueFormat="HH:mm:ss" style="width: 400px"/>
                <a-date-picker v-model="item.value" show-time v-if="Number(item.content) === 2"
                               format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 400px"/>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">备注</td>
            <td colspan="3" style="padding: 10px">
              <a-textarea type="text" v-model="task_info.remark"/>
            </td>
            <td class="table_title_bg">任务加急</td>
            <td>
              <a-radio-group v-model="task_info.is_fast" name="radioGroup">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="2">
                  否
                </a-radio>
              </a-radio-group>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div v-for="(item,k) in item_list" :key="k">
          <table class="layui-table">
            <colgroup>
              <col width="150">
              <col width="200">
              <col width="150">
              <col width="200">
              <col width="150">
              <col width="200">
            </colgroup>
            <tbody>
            <tr>
              <td class="fcb">样品名称</td>
              <td class="text-l">
                <a-input v-model="item.name" style="width: 200px" placeholder="样品名称"></a-input>
              </td>
              <td class="fcb">样品编号</td>
              <td class="text-l">
                <a-space style="position: relative">
                  <a-input placeholder="样品编号" class="w300" v-model="item.in_number"></a-input>
                </a-space>
              </td>
              <td class="fcb">样品来源</td>
              <td class="text-l">
                <a-radio-group name="radioGroup" :default-value="1" v-model="item.yangpincaijileixing">
                  <a-radio :value="1">自采</a-radio>
                  <a-radio :value="2">送样</a-radio>
                </a-radio-group>
              </td>
              <td class="fcb">样品数量</td>
              <td class="text-l">1</td>
            </tr>
            <tr v-if="item.yangpincaijileixing===1">
              <td class="fcb">采集人</td>
              <td class="text-l">
                <div>
                  <a-select v-model="item.pick_per" placeholder="请选择" class="w300">
                    <a-select-option :value="0">暂无</a-select-option>
                    <a-select-option :value="ci.id" v-for="(ci,ck) in caijiren_list">{{
                        ci.name
                      }}
                    </a-select-option>
                  </a-select>
                </div>

                <div class="mt-1">
                  <a-select v-model="item.other_pick_per" mode="multiple" style="width: 100%"
                            placeholder="额外采集人" option-label-prop="label">
                    <a-select-option :value="ci.id" v-for="(ci,ck) in caijiren_list" :label="ci.name">
                      {{ ci.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </td>
              <td class="fcb">采集地点</td>
              <td class="text-l">
                <a-input v-model="item.pick_space" style="width: 200px" placeholder="采集地点"></a-input>
              </td>
              <td class="fcb">经纬度</td>
              <td colspan="3" class="text-l">
                <a-input placeholder="经度" style="width: 50px;" v-model="item.jing_dot"></a-input>
                <a-input placeholder="纬度" style="width: 50px;margin-left: 20px;" v-model="item.wei_dot"></a-input>
              </td>
            </tr>
            <tr v-if="item.yangpincaijileixing===2">
              <td class="fcb">送样人</td>
              <td class="text-l">
                <a-input v-model="item.songyangren" style="width: 200px" placeholder="送样人"></a-input>
              </td>
              <td class="fcb">送样单位</td>
              <td class="text-l">
                <a-input v-model="item.caijidanwei" style="width: 200px" placeholder="送样单位"></a-input>
              </td>
              <td class="fcb">采集地点</td>
              <td class="text-l">
                <a-input v-model="item.pick_space" style="width: 200px" placeholder="采集地点"></a-input>
              </td>
              <td class="fcb">来样时状态</td>
              <td class="text-l">
                <a-input v-model="item.laiyangzhuangtai" style="width: 200px" placeholder="状态"></a-input>
              </td>
            </tr>
            <tr>
              <td class="fcb">样品性状</td>
              <td class="text-l">
                <a-input v-model="item.xingzhuang" style="width: 200px" placeholder="样品性状"></a-input>
              </td>
              <td class="fcb">样品包装</td>
              <td class="text-l">
                <a-input v-model="item.baozhuang" style="width: 200px" placeholder="样品包装"></a-input>
              </td>
              <td class="fcb">规格</td>
              <td class="text-l">
                <a-input v-model="item.guige" style="width: 200px" placeholder="样品规格"></a-input>
              </td>
              <td class="fcb">数量单位</td>
              <td class="text-l">
                <a-input v-model="item.shuliangdanwei" style="width: 200px" placeholder="数量单位"></a-input>
              </td>
            </tr>
            <tr>
              <td class="fcb">生产日期</td>
              <td class="text-l">
                <a-date-picker v-model="item.shengchanriqi" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                               class="w300"/>
              </td>
              <td class="fcb">失效日期</td>
              <td class="text-l">
                <a-date-picker v-model="item.shixiaoriqi" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                               class="w300"/>
              </td>
              <td class="fcb">样品批号</td>
              <td class="text-l">
                <a-input v-model="item.yangpinpihao" style="width: 200px" placeholder="样品批号"></a-input>
              </td>
              <td class="fcb">样品类型</td>
              <td class="text-l">
                <a-input v-model="item.yangpinleixing" style="width: 200px" placeholder="样品类型"></a-input>
              </td>
            </tr>
            <tr>
              <td class="fcb">备注</td>
              <td class="text-l" colspan="7">
                <a-textarea class="w500" v-model="item.remark"></a-textarea>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>